<template>
  <div v-if="permission.access">
    <div class="w-100 d-flex align-items-center justify-content-between mb-1">
      <div>
        <label>Show</label>
        <!-- :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" -->
        <v-select
          @on-change="paginateChange"
          :options="perPageOptions"
          v-model="search.paginate"
          :clearable="false"
          class="per-page-selector d-inline-block mx-50"
        />
        <label>entries</label>
      </div>
      <b-dropdown
        v-if="permission.delete"
        text="Action"
        variant="outline-primary"
        :disabled="!select.selected"
      >
        <b-dropdown-item @click="Delete"> Delete </b-dropdown-item>
      </b-dropdown>
    </div>
    <div class="row">
      <div class="col-md-8">
        <b-card no-body>
          <div class="m-2">
            <b-row class="justify-content-end">
              <!-- Search -->
              <b-col cols="12" md="4">
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    v-model="search.search_data"
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                  />
                </div>
              </b-col>
            </b-row>
          </div>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th class="align-middle text-center">Sl</th>
                  <th>Department</th>
                  <th>Department Head</th>
                  <th class="text-center">Status</th>
                  <th class="text-center" v-if="permission.edit">Action</th>
                  <th class="align-middle" v-if="permission.delete">
                    <label
                      class="custom-control custom-checkbox mb-0 d-inline-block"
                    >
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="checkAllDataTables"
                        v-model="selectedAll"
                        @click="selectAll"
                      />
                      <span class="custom-control-label">&nbsp;</span>
                    </label>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(department, index) in departments.data"
                  :key="index"
                  v-if="departments.data.length"
                >
                  <td class="align-middle text-center">
                    {{ departments.from + index }}
                  </td>
                  <td class="align-middle">
                    <b>{{ department.name }} </b>
                  </td>
                  <td class="align-middle">
                    <span v-if="department.department_head">
                      {{ department.department_head.name }}
                    </span>
                    <span v-else>Not Assign</span>
                  </td>
                  <td class="text-center align-middle">
                    <span
                      class="badge"
                      :class="
                        department.status == true
                          ? 'badge-success'
                          : 'badge-danger'
                      "
                    >
                      {{ department.status == true ? "Active" : "Inactive" }}
                    </span>
                  </td>
                  <td class="align-middle text-center">
                    <div class="btn-group">
                      <button
                        v-if="permission.edit"
                        class="btn btn-primary btn-sm"
                        @click="edit(department.id)"
                      >
                        <feather-icon icon="EditIcon" />
                      </button>
                    </div>
                  </td>
                  <td v-if="permission.delete" class="align-middle">
                    <b-form-checkbox
                      v-model="select.data"
                      :value="department.id"
                      name="flavour-3a"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted"
                  >Showing {{ departments.from ? departments.from : 0 }} to
                  {{ departments.to ? departments.to : 0 }} of
                  {{ departments.total ? departments.total : 0 }}
                  entries</span
                >
              </b-col>

              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <pagination
                  :data="departments"
                  @pagination-change-page="getResults"
                  align="right"
                  :show-disabled="true"
                  :limit="5"
                ></pagination>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </div>
      <div class="col-md-4" v-if="permission.create || form.id != ''">
        <b-card :title="(form.id == '' ? 'Create' : 'Update') + ' Department'">
          <b-card-text>
            <validation-observer ref="loginValidation">
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent
                enctype="multipart/form-data"
              >
                <div class="row mb-2">
                  <div class="col-md-12">
                    <b-form-group label="Select Module" label-for="module_id">
                      <validation-provider
                        #default="{ errors }"
                        name="Select Category"
                      >
                        <Select
                          v-model="form.module_id"
                          filterable
                          :clearable="true"
                          name="module_id"
                          id="module_id"
                          placeholder="Select Module"
                        >
                          <Option :value="1">Administrator</Option>
                          <Option :value="2">Human Resources Management</Option>
                          <Option :value="3">Employee</Option>
                        </Select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div class="col-md-12">
                    <b-form-group label="User Type" label-for="user_type_id">
                      <validation-provider
                        #default="{ errors }"
                        name="Select Category"
                      >
                        <Select
                          v-model="form.user_type_id"
                          filterable
                          :clearable="true"
                          name="user_type_id"
                          id="user_type_id"
                          placeholder="User Type"
                        >
                          <Option
                            :value="user_type.id"
                            v-for="(user_type, index) in user_types"
                            :key="index"
                            v-if="user_types.length"
                            >{{ user_type.name }}</Option
                          >
                        </Select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div class="col-md-12">
                    <b-form-group
                      label="Select Department Head"
                      label-for="head_id"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Select Department Head"
                      >
                        <Select
                          v-model="form.head_id"
                          filterable
                          :clearable="true"
                          name="head_id"
                          id="head_id"
                          placeholder="Select Department Head"
                        >
                          <Option
                            :value="employee.id"
                            v-for="(employee, index) in employees"
                            :key="index"
                            v-if="employees.length"
                            >{{ employee.name }}</Option
                          >
                        </Select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div class="col-md-12">
                    <b-form-group label="Department Name" label-for="name">
                      <validation-provider
                        #default="{ errors }"
                        name="Department Name"
                        rules="required"
                      >
                        <Input
                          name="name"
                          type="text"
                          v-model="form.name"
                          id="name"
                          placeholder="Enter Department Name"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                </div>
                <b-form-checkbox
                  name="status"
                  style="margin-top: 5px"
                  v-model="form.status"
                  switch
                  inline
                >
                  Status
                </b-form-checkbox>
                <b-button
                  type="submit"
                  class="float-right"
                  variant="primary"
                  @click="validationForm"
                >
                  {{ form.id == "" ? "Create" : "Update" }}
                </b-button>
                <b-button
                  type="submit"
                  class="float-right mx-1"
                  variant="outline-primary"
                  @click="Close"
                >
                  Close
                </b-button>
              </b-form>
            </validation-observer>
          </b-card-text>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  BCard,
  BMediaBody,
  BMediaAside,
  BMedia,
  BFormFile,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BFormTextarea,
  BFormCheckboxGroup,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
} from "bootstrap-vue";
import { required } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import _ from "lodash";
export default {
  data() {
    return {
      selected: [],
      search: {
        category_id: "",
        search_data: "",
        paginate: 10,
        page: 1,
      },
      perPageOptions: [10, 25, 50, 100],
      form: {
        id: "",
        module_id: null,
        user_type_id: null,
        head_id: null,
        name: null,
        status: true,
      },
      departments: {},

      select: {
        employee_id: null,
        data: [],
        selected: false,
      },
      selectedAll: false,
    };
  },
  mounted() {
    this.getResults();
    this.$store.dispatch("getEmployee");
    this.$store.dispatch("getUserType");
  },
  methods: {
    async getResults(page) {
      this.search.page = page == null ? this.search.page : page;
      const departments = await this.callApi(
        "post",
        "/app/departments?page=" + this.search.page,
        this.search
      );
      if (departments.status == 200) {
        this.departments = departments.data.departments;
      }
    },

    Delete() {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't to delete it!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.DeleteAlert("/app/departments/delete", this.select);
          this.select.data = [];
          this.getResults();
        }
      });
    },

    Close() {
      this.form.id = "";
      this.form.module_id = null;
      this.form.user_type_id = null;
      this.form.head_id = null;
      this.form.name = null;
      this.form.status = true;
    },

    async edit(id) {
      const department = await this.callApi(
        "post",
        "/app/departments/edit/" + id
      );
      if (department.status == 200) {
        this.form.id = department.data.department.id;
        this.form.module_id = department.data.department.module_id;
        this.form.user_type_id = department.data.department.user_type_id;
        this.form.head_id = department.data.department.head_id;
        this.form.name = department.data.department.name;
        this.form.status = department.data.department.status;
        this.form.status =
          department.data.department.status == true ? true : false;
      }
    },

    add() {
      axios
        .post("/app/departments/store", this.form)
        .then((res) => {
          this.s(res.data.message);
          this.getResults();
          this.Close();
        })
        .catch((e) => {
          if (e.response.status === 422) {
            if (e.response.data.errors.module_id)
              this.e(e.response.data.errors.module_id[0]);
            if (e.response.data.errors.user_type_id)
              this.e(e.response.data.errors.user_type_id[0]);
            if (e.response.data.errors.head_id)
              this.e(e.response.data.errors.head_id[0]);
            if (e.response.data.errors.name)
              this.e(e.response.data.errors.name[0]);
            if (e.response.data.errors.status)
              this.e(e.response.data.errors.status[0]);
          }
        });
    },

    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.add();
        }
      });
    },

    // Select Data for Delete
    selectAll(event) {
      if (event.target.checked == false) {
        this.select.data = [];
      } else {
        this.select.data = [];
        this.departments.data.forEach((category) => {
          this.select.data.push(category.id);
        });
      }
    },

    SearchData: _.debounce(function () {
      this.search.page = 1;
      this.getResults();
    }, 1000),

    // Paginate Data Gate
    paginateChange() {
      this.search.page = 1;
      this.getResults();
    },
  },
  watch: {
    "search.search_data": function (val) {
      this.SearchData();
    },
    "search.paginate": function (val) {
      this.paginateChange();
    },
    "select.data": function (selected) {
      this.select.selected = selected.length > 0;
      this.selectedAll = selected.length == this.departments.data.length;
    },
  },
  components: {
    vSelect,
    BCard,
    BCardText,
    BLink,
    BTabs,
    BTab,
    BMedia,
    BFormFile,
    BFormTextarea,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BMediaAside,
    BMediaBody,
    BFormCheckboxGroup,
    BDropdown,
    BDropdownDivider,
    BDropdownItem,
  },
  computed: {
    employees() {
      return this.$store.getters.getEmployee;
    },
    user_types() {
      return this.$store.getters.getUserType;
    },
    permission() {
      return this.$store.getters.getPermissions.department;
    },
  },
};
</script>

<style></style>
